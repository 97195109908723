<template>
  <footer ref="mainFooter" class="footer bg-dark text-white w-100 mt-auto mb-0" role="contentinfo">
    <div class="flex-grow-1">
      <div class="container" :class="isMobile ? 'pt-5 pb-3' : 'pt-4 pb-5'">
        <div class="row">
          <div
              v-for="(section, index) in footerSections"
              v-bind:key="section.id"
              class="col-md-4 text-center footer-section"
              :class="`footer-section--${index}`"
          >
            <p class="footer-section-header">
              <template v-if="section.name.toLowerCase() === 'logo'">
                <img
                    v-if="siteLogoUrl"
                    :src="siteLogoUrl"
                    :alt="`Logo ${TITLE}`"
                    class="navbar-logo"
                />
              </template>
              <template v-else>
                {{ section.name }}
              </template>
            </p>
            <ul class="list-unstyled mb-4">
              <li
                v-for="element in section.elements"
                v-bind:key="element.id"
                class="text-white"
              >
                <span class="footer-link text-white">
                  <template v-if="element.url">
                    <a :href="element.url" class="clickable-footer-link">
                      {{ element.text }}
                    </a>
                  </template>
                  <template v-else>
                    {{ element.text }}
                  </template>
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { getFooterSections } from '@/services/footer'
import { TITLE } from '@/plugins/constants'

export default {
  name: 'MainFooter',
  data() {
    return {
      footerSections: [],
      TITLE
    }
  },
  computed: {
    siteLogoUrl() {
      return this.$store.getters.siteLogoUrl
    }
  },
  async created() {
    this.footerSections = await getFooterSections()
  }
}
</script>
