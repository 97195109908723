import { 
    SESSION_STORAGE__CURRENCY_CODE,
    DEFAULT_CURRENCY_CODE,
    SESSION_STORAGE__CURRENCY_DATA,
    AVAILABLE_CURRENCIES
} from '@/plugins/constants';
import axios from '@/plugins/axios'
import { getLocale } from '@/plugins/languages'

const getCurrencyFromLocale = () => {
    const locale = getLocale()

    return locale === 'en' ? 'eur' : DEFAULT_CURRENCY_CODE
}

export const getCurrencyCode = () => {
    const currencyCode = sessionStorage.getItem(SESSION_STORAGE__CURRENCY_CODE) ?? getCurrencyFromLocale()
    
    if (!currencyCode || !AVAILABLE_CURRENCIES.includes(currencyCode)) {
        return DEFAULT_CURRENCY_CODE
    }

    return currencyCode
}

const getStoredCurrencyData = () => {
    let storedCurrencyData = sessionStorage.getItem(SESSION_STORAGE__CURRENCY_DATA)

    if (storedCurrencyData) {
        storedCurrencyData = JSON.parse(storedCurrencyData)

        if (storedCurrencyData) {
            return storedCurrencyData
        }
    }

    return null
}

export const getCurrencyData = async () => {
    const currencyCode = getCurrencyCode()
    let storedCurrencyData = getStoredCurrencyData()

    if (storedCurrencyData && storedCurrencyData.code === currencyCode) {
        return storedCurrencyData
    }

    let currencyData = await axios.get(`${process.env.VUE_APP_BACKEND_URL}/api/currency/${currencyCode}`)

    sessionStorage.setItem(
        SESSION_STORAGE__CURRENCY_CODE,
        currencyData.data.code
    )
    sessionStorage.setItem(
        SESSION_STORAGE__CURRENCY_DATA,
        JSON.stringify(currencyData.data)
    )

    return currencyData.data
}

export const formatPriceString = (price) => {
    const currencyData = getStoredCurrencyData()

    return `${price.toFixed(2)} ${currencyData.sign}`
}
