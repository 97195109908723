<template>
  <div class="wrapper">
    <MainHeader
        :key="mainHeaderKey"
        :main-hovered="mainHovered"
    />
    <main
        role="main"
        @mouseenter="mainHovered = true"
        @mouseleave="mainHovered = false"
    >
      <router-view
          @flash-alert="flashAlert"
      />
    </main>
    <MainFooter />
    <template
        v-for="alertData in alerts"
        v-bind:key="alertData.id"
    >
      <AsyncAlert
          :id="alertData.id"
          :type="alertData.type"
          :title="alertData.title"
          :message="alertData.message"
          @close="removeAlert"
      />
    </template>
  </div>
  <div v-if="!cookiesAccepted && cookiesModalData && cookiesModalData.active" @click="hideCookies" class="cookies-modal cursor-pointer">
    <p v-if="cookiesModalData.title">
      {{ cookiesModalData.title }}
    </p>
    <div class="w-5 cookies-close ml-auto mr-0">
      <button
          class="btn close p-0"
          type="button"
          aria-label="Zamknij"
          @click="hideCookies"
      >
        <font-awesome-icon icon="times" size="xs" />
      </button>
    </div>
    <div class="w-95p cookies-content" v-html="cookiesModalData.text"/>
  </div>
  <div
      class="fb-chat-icon cursor-pointer"
      @click="goToFb"
  />
</template>

<script>
import MainHeader from '@/components/MainHeader'
import MainFooter from '@/components/MainFooter'
import { defineAsyncComponent } from 'vue'
import {
  ALERT_TIMEOUT,
  LOCAL_STORAGE__COOKIES_ACCEPTED,
} from '@/plugins/constants'
import { generateRandomString, refreshCartDropdown } from '@/plugins/helpers'
import { getCookiesModalData } from '@/services/homepage'


const AsyncAlert = defineAsyncComponent(() => import('@/components/Alert'))

export default {
  name: 'App',
  components: {
    MainHeader,
    MainFooter,
    AsyncAlert,
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
      alerts: [],
      mainHeaderKey: 0,
      mainHovered: false,
      cookiesAccepted: false
    }
  },
  async mounted() {
    this.cookiesAccepted = localStorage.getItem(LOCAL_STORAGE__COOKIES_ACCEPTED)
    window.addEventListener('resize', this.resizeApp)

    setTimeout(() => {
      if (!this.$store.getters.hasCartDropdownData) {
        refreshCartDropdown()
      }
    }, 300)

    if (!this.cookiesAccepted) {
      try {
        this.cookiesModalData = await getCookiesModalData()
      } catch (e) {
        console.warn(e)
      }
    }

    setTimeout(() => {
      const chatIcon = document.querySelector('.fb-chat-icon')
      chatIcon.classList.add('visible')
    }, 100)
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.resizeApp)
  },
  methods: {
    goToFb() {
      window.open('https://m.me/113803287191260', '_blank')
    },
    resizeApp() {
      this.mainHeaderKey += 1
    },
    /**
     * @param {Object} alertData
     * @param {string} alertData.type - Alert type (from constant: ALERT_TYPES).
     * @param {string} alertData.title
     * @param {string} alertData.message
     */
    flashAlert(alertData) {
      const alertId = generateRandomString()

      this.alerts.push({
        id: alertId,
        ...alertData
      })

      setTimeout(() => {
        this.removeAlert(alertId)
      }, ALERT_TIMEOUT)
    },
    hideCookies() {
      localStorage.setItem(LOCAL_STORAGE__COOKIES_ACCEPTED, '1')
      this.cookiesAccepted = true
    },
    removeAlert(alertId) {
      const alertIndex = this.alerts.findIndex(alert => alert.id === alertId)

      if (alertIndex !== -1) {
        this.alerts.splice(alertIndex, 1)
      }
    }
  }
}
</script>

<style lang="scss">
@import '@/styles/global.scss';
</style>
