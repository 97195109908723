<template>
  <div
      class="dropdown languages-dropdown"
      :class="additionalClass"
  >
    <button
        class="btn btn-sm dropdown-toggle border-0"
        type="button"
        id="language-dropdown"
        data-bs-toggle="dropdown"
        aria-expanded="false"
    >
      <img
          v-if="currentLocaleImageSrc"
          :src="currentLocaleImageSrc"
          :alt="currentLocaleIso"
          width="64"
          height="32"
          loading="lazy"
      />
    </button>
    <ul class="dropdown-menu" aria-labelledby="language-dropdown">
      <li
          v-for="(language, index) in languageChoices"
          v-bind:key="index"
      >
        <router-link
            :to="`/${language.code}`"
            class="dropdown-item"
            :aria-label="language.name"
        >
          <img
              :src="language.imgSrc"
              :alt="language.code"
              width="64"
              height="32"
              loading="lazy"
          />
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { getLocale } from '@/plugins/languages'

export default {
  name: 'LanguagesDropdown',
  props: [
    'additionalClass'
  ],
  data() {
    return {
      currentLocaleIso: null,
      currentLocale: {},
      languageChoices: [],
      languages: [
        { code: 'pl', name: 'polski', imgSrc: '/img/languages/pl.webp' },
        { code: 'en', name: 'angielski', imgSrc: '/img/languages/en.webp' },
      ]
    }
  },
  computed: {
    currentLocaleImageSrc() {
      return this.currentLocaleIso ? this.languages.filter(l => l.code === this.currentLocaleIso)[0].imgSrc : ''
    }
  },
  methods: {
    getLocalesData() {
      this.currentLocaleIso = getLocale()
      this.currentLocale = this.languages.filter(language => language.code === this.currentLocaleIso)[0]
      this.languageChoices = this.languages.filter(language => language.code !== this.currentLocaleIso)
    }
  },
  watch: {
    $route() {
      this.getLocalesData()
    },
  },
  created() {
    this.getLocalesData()
  }
}
</script>
