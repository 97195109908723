import axios from '@/plugins/axios'
import { refreshCartDropdown, setCartUUID } from '@/plugins/helpers'

export const getCart = async (isAuthenticated = true, uuid = null) => {
    const url = `${process.env.VUE_APP_BACKEND_URL}/api/cart/${isAuthenticated ? 'customer' : 'anonymous'}`,
        queryParams = {}

    if (!isAuthenticated) {
        queryParams['cartUUID'] = uuid
    }

    const response = await axios.get(url, { params: queryParams })

    refreshCartDropdown()

    return response.data
}

export const getCustomCart = async (uuid) => {
    const url = `${process.env.VUE_APP_BACKEND_URL}/api/cart/custom`,
        queryParams = {}

    queryParams['cartUUID'] = uuid

    const response = await axios.get(url, { params: queryParams })

    return response.data
}

export const shareCart = async (uuid) => {
    const url = `${process.env.VUE_APP_BACKEND_URL}/api/cart/share`,
        data = {
            cartUUID: uuid
        }

    const response = await axios.patch(url, data)

    return response.data
}

export const addToCart = async (productIdentity, inputs, options, quantity = 1, isAuthenticated = true, uuid = null) => {
    const url = `${process.env.VUE_APP_BACKEND_URL}/api/cart/${isAuthenticated ? 'customer' : 'anonymous'}`

    const postData = {
        productIdentity,
        cartUUID: uuid,
        inputs,
        options,
        quantity
    }

    const response = await axios.post(url, postData)
    const data = response.data

    if (data.uuid) {
        setCartUUID(data.uuid)
        refreshCartDropdown()
    }

    return data
}

export const removeProductFormCart = async (uuid, productId) => {
    const url = `${process.env.VUE_APP_BACKEND_URL}/api/cart/${uuid}/product/${productId}`

    const response = await axios.delete(url)
    refreshCartDropdown()

    return response.data
}

export const getCartDropdown = async (cartUUID, isAuthenticated) => {
    const url = `${process.env.VUE_APP_BACKEND_URL}/api/cart/dropdown`

    const params = {
        cartUUID,
        isAuthenticated
    }

    const response = await axios.get(url, { params })

    setCartUUID(response.data.uuid, isAuthenticated)

    return response.data
}

export const useCartDiscountCode = async (uuid, discountCode) => {
    const url = `${process.env.VUE_APP_BACKEND_URL}/api/discount-code/cart`
    const postData = {
        cartUUID: uuid,
        discountCode: discountCode
    }

    const response = await axios.post(url, postData)

    return response.data
}

export const removeCartDiscountCode = async (uuid) => {
    const url = `${process.env.VUE_APP_BACKEND_URL}/api/discount-code/cart`
    const patchData = {
        cartUUID: uuid,
    }

    const response = await axios.patch(url, patchData)

    return response.data
}

export const acceptCartFirstStep = async (cartUUID, shipmentData, paymentTypeId) => {
    const url = `${process.env.VUE_APP_BACKEND_URL}/api/cart/first-step`
    const patchData = {
        cartUUID,
        shipmentData,
        paymentTypeId
    }

    await axios.patch(url, patchData)
}

export const goBackToCartFirstStep = async (cartUUID) => {
    const url = `${process.env.VUE_APP_BACKEND_URL}/api/cart/first-step`
    const patchData = {
        cartUUID,
    }

    await axios.post(url, patchData)
}

export const order = async (data) => {
    const url = `${process.env.VUE_APP_BACKEND_URL}/api/orders`

    const response = await axios.post(url, data)

    return response.data
}