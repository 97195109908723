import axios from '@/plugins/axios'
import { SESSION_STORAGE__FOOTER_SECTIONS } from '@/plugins/constants'

export const getFooterSections = async () => {
    let footerSections = sessionStorage.getItem(SESSION_STORAGE__FOOTER_SECTIONS)

    if (footerSections) {
        return JSON.parse(footerSections)
    }

    const url = `${process.env.VUE_APP_BACKEND_URL}/api/footer`
    const response = await axios.get(url)

    footerSections = response.data
    sessionStorage.setItem(SESSION_STORAGE__FOOTER_SECTIONS, JSON.stringify(footerSections))

    return footerSections
}