window.bootstrap = require('bootstrap')

import { createApp } from 'vue'
import App from './App.vue'
import router from '@/router'
import store from '@/store'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { MOBILE_MAX_WIDTH_PX } from '@/plugins/constants'
import { loadIcons } from '@/plugins/icons'
import getI18n from '@/plugins/languages'
import { getCurrencyData, formatPriceString } from '@/plugins/currency'

const i18n = await getI18n(),
    app = createApp(App)

await getCurrencyData()

app.use(router)
app.use(store)

app.component('font-awesome-icon', FontAwesomeIcon)

// app.use(VueReCaptcha, {siteKey: process.env.VUE_RECAPTCHA_KEY})

app.config.globalProperties.isMobile = window.matchMedia(`(max-width: ${MOBILE_MAX_WIDTH_PX}px)`).matches
app.config.globalProperties.$formatPriceString = formatPriceString

window.addEventListener('resize', () => {
    app.config.globalProperties.isMobile = window.matchMedia(`(max-width: ${MOBILE_MAX_WIDTH_PX}px)`).matches
})

app.use(i18n)

loadIcons().then(() => {
    app.mount('#app')
})
