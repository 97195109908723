import axios from '@/plugins/axios'
import { SESSION_STORAGE__NAVBAR_SECTIONS } from '@/plugins/constants'

export const getNavbarSections = async () => {
    let navbarSections = sessionStorage.getItem(SESSION_STORAGE__NAVBAR_SECTIONS)

    if (navbarSections) {
        return JSON.parse(navbarSections)
    }

    const url = `${process.env.VUE_APP_BACKEND_URL}/api/navbar`
    const response = await axios.get(url)

    navbarSections = response.data
    sessionStorage.setItem(SESSION_STORAGE__NAVBAR_SECTIONS, JSON.stringify(navbarSections))

    return navbarSections
}