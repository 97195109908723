import { createStore } from 'vuex'
import { SESSION_STORAGE__TOKEN } from '@/plugins/constants'

const initialState = {
    token: sessionStorage.getItem(SESSION_STORAGE__TOKEN),
    cartDropdown: null,
    loadingCartDropdown: false,
    siteLogoUrl: null
}

const store = createStore({
    state: initialState,
    getters: {
        isAuthenticated: state => state.token !== null,
        cartProductsCount: state => state.cartDropdown != null ? state.cartDropdown['productsCount'] : 0,
        hasCartDropdownData: state => state.cartDropdown != null,
        cartDropdownData: state => state.cartDropdown,
        isLoadingCartDropdown: state => state.loadingCartDropdown,
        siteLogoUrl: state => state.siteLogoUrl
    },
    mutations: {
        setSiteLogoUrl(state, siteLogoUrl) {
            state.siteLogoUrl = siteLogoUrl
        },
        setAuthentication(state, token) {
            state.token = token
        },
        setCartDropdown(state, cartDropdown) {
            state.cartDropdown = cartDropdown
        },
        setLoadingCartDropdown(state, loading) {
            state.loadingCartDropdown = loading
        },
    },
    actions: {}
})

export default store