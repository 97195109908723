export const loadIcons = async () => {
    const {
        library
    } = await import('@fortawesome/fontawesome-svg-core')

    const {
        faCircle,
        faBars,
        faCartShopping,
        faUser,
        faPlus,
        faMinus,
        faSignOut,
        faUpload,
        faPaperclip,
        faInfoCircle,
        faTimes,
        faLongArrowAltLeft,
        faChevronRight,
        faChevronDown,
        faX,
        faCheckSquare,
        faPen,
        faList,
        faAngleDoubleDown,
        faAngleDoubleRight
    } = await import('@fortawesome/free-solid-svg-icons')

    library.add(
        faCircle,
        faBars,
        faCartShopping,
        faUser,
        faPlus,
        faMinus,
        faSignOut,
        faUpload,
        faPaperclip,
        faInfoCircle,
        faTimes,
        faLongArrowAltLeft,
        faChevronRight,
        faChevronDown,
        faX,
        faCheckSquare,
        faPen,
        faList,
        faAngleDoubleDown,
        faAngleDoubleRight
    )
}
