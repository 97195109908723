<template>
  <header class="navbar navbar-dark bg-dark">
    <nav class="navbar navbar-inverse navbar-expand-lg text-uppercase">
      <div v-if="!loading" class="container text-white">
        <MainHeaderToggler
            @toggle-menu="toggleMenu"
        />
        <router-link
            to="/"
            @mouseover="hideNavbarCategories"
        >
          <Loader v-if="loading" />
          <img v-else-if="siteLogoUrl" :src="siteLogoUrl" :alt="`Logo ${TITLE}`" class="navbar-logo" />
        </router-link>

        <template v-if="!isMobile">
          <MainHeaderCategoriesDesktop
              ref="mainHeaderCategoriesDesktopRef"
              :navbar-sections="navbarSections"
              @mouseover="closeDropdownCart"
          />
        </template>

        <MainHeaderIcons
            :cartOpened="cartOpened"
            @openDropdownCart="openDropdownCart"
            @closeDropdownCart="closeDropdownCart"
        />
        <MainHeaderCategoriesMobile
            v-if="isMobile"
            :navbar-sections="navbarSections"
            :is-menu-open="isMenuOpen"
        />

        <LanguagesDropdown
            v-if="!isMobile"
            @mouseover="closeDropdownCart"
        />
      </div>
    </nav>
  </header>
</template>

<script>
import { TITLE } from '@/plugins/constants'
import MainHeaderCategoriesDesktop from '@/components/MainHeaderCategoriesDesktop'
import MainHeaderCategoriesMobile from '@/components/MainHeaderCategoriesMobile'
import MainHeaderIcons from '@/components/MainHeaderIcons'
import MainHeaderToggler from '@/components/MainHeaderToggler'
import LanguagesDropdown from '@/components/LanguagesDropdown'
import { getNavbarSections } from '@/services/navbar'
import Loader from '@/components/Loader'

export default {
  name: 'MainHeader',
  props: [
      'mainHovered'
  ],
  components: {
    MainHeaderCategoriesDesktop,
    MainHeaderCategoriesMobile,
    MainHeaderIcons,
    MainHeaderToggler,
    LanguagesDropdown,
    Loader
  },
  data() {
    return {
      TITLE,
      isMenuOpen: false,
      navbarSections: [],
      siteLogoUrl: null,
      cartOpened: false,
      loading: false,
    }
  },
  async created() {
    this.loading = true
    const data = await getNavbarSections()
    this.navbarSections = data['sections']
    this.siteLogoUrl = data['siteLogoUrl']
    this.$store.commit('setSiteLogoUrl', data['siteLogoUrl'])
    this.loading = false
  },
  computed: {
    isAuthenticated() {
      return this.$store.getters.isAuthenticated
    }
  },
  watch: {
    $route() {
      this.enableScroll()
      this.isMenuOpen = false
      this.closeDropdownCart()
      this.hideNavbarCategories()
    },
    mainHovered() {
      if (this.mainHovered) {
        this.closeDropdownCart()
        this.hideNavbarCategories()
      }
    }
  },
  methods: {
    hideNavbarCategories() {
      if (this.$refs.mainHeaderCategoriesDesktopRef) {
        this.$refs.mainHeaderCategoriesDesktopRef.hideNavbarCategories()
      }
    },
    closeDropdownCart() {
      this.cartOpened = false
    },
    openDropdownCart() {
      this.cartOpened = true
    },
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen
      this.handleScroll()
    },
    handleScroll() {
      if (this.isMenuOpen) {
        this.disableScroll()
        const nav = document.querySelector('header.navbar')

        if (nav) {
          nav.classList.add('nav-bottom-0')
        }
      } else {
        this.enableScroll()

        const nav = document.querySelector('header.navbar')

        if (nav && nav.classList.contains('nav-bottom-0')) {
          nav.classList.remove('nav-bottom-0')
        }
      }
    },
    disableScroll() {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop
      const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft

      window.onscroll = function() {
        window.scrollTo(scrollLeft, scrollTop)
      }
    },
    enableScroll() {
      window.onscroll = function() {}
    }
  },
  beforeUnmount() {
    this.enableScroll()
  }
}
</script>