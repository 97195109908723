import axios from '@/plugins/axios'

export const getHomepageGalleries = async () => {
    const url = `${process.env.VUE_APP_BACKEND_URL}/api/homepage`
    const response = await axios.get(url)

    return response.data['homepageGalleries']
}

export const getCookiesModalData = async () => {
    const url = `${process.env.VUE_APP_BACKEND_URL}/api/cookies-modal`
    const response = await axios.get(url)

    return response.data
}
