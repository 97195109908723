<template>
  <div v-if="isMenuOpen" class="navbar-categories">
    <template v-if="isAuthenticated">
      <a href="/account" class="nav-link navbar-category navbar-user">
        <div class="d-inline-flex w-90">
          {{ $t('header.my_account') }}
        </div>
        <button class="btn btn-sm btn-dark border-0" role="button">
          <font-awesome-icon icon="user" />
        </button>
      </a>
    </template>
    <template v-else>
      <a href="/login" class="nav-link navbar-category navbar-user">
        <div class="d-inline-flex w-90">
          {{ $t('messages.sign_in') }}
        </div>
        <button class="btn btn-sm btn-dark border-0" role="button">
          <font-awesome-icon icon="user" />
        </button>
      </a>
    </template>
    <div
        v-for="navbarCategory in navbarSections"
        v-bind:key="navbarCategory.identity"
        class="navbar-category-container"
        :class="{'border-bottom mb-3': isCategoryOpen(navbarCategory.productGroupId)}"
    >
      <template v-if="navbarCategory.children && navbarCategory.children.length">
      <div class="navbar-category d-flex" :class="{'border-0': isCategoryOpen(navbarCategory.productGroupId)}">
        <a
            :href="navbarCategory.url ? navbarCategory.url : (navbarCategory.productGroupId ? `/group/${navbarCategory.productGroupId}` : '')"
            class="nav-link d-inline-flex w-90"
        >
          {{ navbarCategory.name }}
        </a>
        <button
            v-if="navbarCategory.children && navbarCategory.children.length"
            class="btn btn-dark border-0"
            role="button"
            aria-label="Toggle header category"
            @click="toggleCategory(navbarCategory.productGroupId)"
        >
          <template v-if="isCategoryOpen(navbarCategory.productGroupId)">
            <font-awesome-icon icon="minus" />
          </template>
          <template v-else>
            <font-awesome-icon icon="plus" />
          </template>
        </button>
      </div>
      <div
          v-if="isCategoryOpen(navbarCategory.productGroupId)"
          class="navbar-category-submenu-mobile px-3"
      >
        <template
            v-for="navbarCategory2 in navbarCategory.children"
            :key="`navbar-category-2-${navbarCategory2.productGroupId}`"
        >
          <div>
            <a
                class="mb-3 pb-2 fw-bold fs-5 d-block text-decoration-none text-white border-bottom w-75"
                :href="`/group/${navbarCategory2.productGroupId}`"
            >
              {{ navbarCategory2.name }}
            </a>

            <a
                v-for="navbarCategory3 in navbarCategory2.children"
                :key="`navbar-category-3-${navbarCategory3.productGroupId}`"
                class="fs-6 text-decoration-none text-white navbar-category navbar-category-submenu-mobile-href border-0 nav-link"
                :href="`/group/${navbarCategory3.productGroupId}`"
            >
              {{ navbarCategory3.name }}
            </a>
          </div>
        </template>
      </div>
      </template>
      <template v-else>
        <div
            class="navbar-category d-flex"
            :class="{'border-0': isCategoryOpen(`el-${navbarCategory.identity}`)}"
        >
          <a
              :href="navbarCategory.url"
              class="nav-link d-inline-flex w-90"
          >
            {{ navbarCategory.name }}
          </a>
          <button
              v-if="navbarCategory.elements && navbarCategory.elements.length"
              class="btn btn-dark border-0"
              role="button"
              aria-label="Toggle header category"
              @click="toggleCategory(`el-${navbarCategory.identity}`)"
          >
            <template v-if="isCategoryOpen(`el-${navbarCategory.identity}`)">
              <font-awesome-icon icon="minus" />
            </template>
            <template v-else>
              <font-awesome-icon icon="plus" />
            </template>
          </button>
        </div>
        <div
            v-if="isCategoryOpen(`el-${navbarCategory.identity}`)"
            class="navbar-category-submenu-mobile px-3"
        >
          <a
              v-for="element in navbarCategory.elements"
              :key="element.identity"
              :href="element.url"
              class="navbar-category navbar-category-submenu-mobile-href border-bottom nav-link"
          >
            {{ element.name }}
          </a>
        </div>
      </template>
    </div>
    <template v-if="isAuthenticated">
      <a href="/logout" class="nav-link text-danger navbar-category navbar-logout">
        <div class="d-inline-flex w-90">
          {{ $t('messages.sign_out') }}
        </div>
        <button class="btn btn-sm btn-dark text-danger border-0" role="button">
          <font-awesome-icon icon="sign-out" />
        </button>
      </a>
    </template>
    <LanguagesDropdown />
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import LanguagesDropdown from '@/components/LanguagesDropdown'

export default {
  name: 'MainHeaderCategoriesMobile',
  components: {
    FontAwesomeIcon,
    LanguagesDropdown
  },
  props: [
    'navbarSections',
    'isMenuOpen',
  ],
  computed: {
    isAuthenticated() {
      return this.$store.getters.isAuthenticated
    }
  },
  data() {
    return {
      openedCategoriesIds: [],
    }
  },
  methods: {
    isCategoryOpen(categoryId) {
      if (!categoryId) {
        return false
      }

      return this.openedCategoriesIds.includes(categoryId)
    },
    toggleCategory(categoryId) {
      if (this.isCategoryOpen(categoryId)) {
        this.closeCategory(categoryId)
      } else {
        this.openCategory(categoryId)
      }
    },
    openCategory(categoryId) {
      this.openedCategoriesIds.push(categoryId)
    },
    closeCategory(categoryId) {
      const index = this.openedCategoriesIds.indexOf(categoryId)

      if (index !== -1) {
        this.openedCategoriesIds.splice(index, 1)
      }
    }
  }
}

</script>
