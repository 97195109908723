import {
    createRouter,
    createWebHistory
} from 'vue-router'
import {
    TITLE
} from '@/plugins/constants'

const staticPages = [
    {
        path: '/static/:slug',
        name: 'StaticPageView',
        component: () => import('@/views/StaticPageView')
    },
]

const routes = [
    {
        path: '/',
        name: 'HomeView',
        component: () => import('@/views/HomeView'),
    },
    {
        path: '/pl',
        name: 'ChangeLanguageViewPL',
        component: () => import('@/views/ChangeLanguageView')
    },
    {
        path: '/en',
        name: 'ChangeLanguageViewEN',
        component: () => import('@/views/ChangeLanguageView')
    },
    {
        path: '/login',
        name: 'LoginView',
        component: () => import('@/views/LoginView'),
        meta: {
            title: 'Logowanie'
        }
    },
    {
        path: '/logout',
        name: 'LogoutView',
        component: () => import('@/views/LogoutView'),
        meta: {
            title: 'Wylogowywanie...'
        }
    },
    {
        path: '/register',
        name: 'RegisterView',
        component: () => import('@/views/RegisterView'),
        meta: {
            title: 'Rejestracja'
        }
    },
    {
        path: '/forgotten-password',
        name: 'ForgottenPasswordView',
        component: () => import('@/views/ForgottenPasswordView'),
        meta: {
            title: 'Zapomniałem hasła'
        }
    },
    {
        path: '/reset-password/:uuid',
        name: 'ResetPasswordView',
        component: () => import('@/views/ResetPasswordView'),
        meta: {
            title: 'Reset hasła'
        }
    },
    {
        path: '/contact',
        name: 'ContactView',
        component: () => import('@/views/ContactView'),
        meta: {
            title: 'Kontakt'
        }
    },
    {
        path: '/account',
        name: 'AccountView',
        component: () => import('@/views/AccountView'),
        meta: {
            title: 'Moje konto'
        }
    },
    {
        path: '/group/:productGroupId',
        name: 'ProductGroupView',
        component: () => import('@/views/ProductGroupView'),
        meta: {
            title: 'Grupa produktowa'
        }
    },
    {
        path: '/product/:productSlug',
        name: 'ProductView',
        component: () => import('@/views/ProductView'),
        meta: {
            title: 'Produkt'
        }
    },
    {
        path: '/projekty-personalizowane',
        name: 'CustomProjectView',
        component: () => import('@/views/CustomProjectView'),
        meta: {
            title: 'Projekty personalizowane'
        }
    },
    {
        path: '/cart',  // step 1
        name: 'CartView',
        component: () => import('@/views/CartView'),
        meta: {
            title: 'Koszyk'
        }
    },
    {
        path: '/cart/addresses',  // step 2
        name: 'CartAddressesView',
        component: () => import('@/views/CartAddressesView'),
        meta: {
            title: 'Koszyk - adresy'
        }
    },
    {
        path: '/cart/success/:orderHash',
        name: 'CartSuccessView',
        component: () => import('@/views/CartSuccessView'),
        meta: {
            title: 'Zamówienie'
        }
    },
    {
        path: '/cart/custom/:uuid',
        name: 'CustomCartView',
        component: () => import('@/views/CartView'),
        meta: {
            title: 'Koszyk udostępniony'
        }
    },
    {
        path: '/cart/custom/addresses/:uuid',
        name: 'CustomCartAddressesView',
        component: () => import('@/views/CartAddressesView'),
        meta: {
            title: 'Koszyk - adresy'
        }
    },
    {
        path: '/gallery',
        name: 'GalleryView',
        component: () => import('@/views/GalleryView'),
        meta: {
            title: 'Galeria'
        }
    },
    ...staticPages,
    {
        path: '/:notFound',
        component: () => import('@/views/NotFoundView'),
        meta: {
            title: 'Nie znaleziono strony'
        }
    },
    {
        path: '/produkt/:notFound',
        component: () => import('@/views/NotFoundView'),
        meta: {
            title: 'Nie znaleziono produktu'
        }
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

router.beforeEach((to, from, next) => {
    if (to.meta && to.meta.title) {
        document.title = `${to.meta.title} - ${TITLE}`
    } else {
        document.title = TITLE
    }

    next()
})

export default router
