import axios from 'axios'
import store from '@/store'
import { clearAuthentication } from '@/plugins/helpers'
import { DEFAULT_NOT_AUTHENTICATED_REDIRECT_URL } from '@/plugins/constants'
import { getLocale } from '@/plugins/languages'
import { getCurrencyCode } from '@/plugins/currency'

axios.interceptors.request.use(config => {
    const token = store.state.token,
        locale = getLocale()

    if (token) {
        config.headers.Authorization = `JWT ${token}`
    }

    config.headers['LOCALE-CODE'] = locale
    config.headers['CURRENCY-CODE'] = getCurrencyCode()

    return config
})

axios.interceptors.response.use(
    response => response,
    error => {
        // todo (optional): attach sentry here

        if (error.response && error.response.status === 403) {
            if (store.getters.isAuthenticated) {
                clearAuthentication()
            }

            window.location.href = DEFAULT_NOT_AUTHENTICATED_REDIRECT_URL
        }

        return Promise.reject(error)
    }
)

export default axios
