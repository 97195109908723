import store from '@/store'
import {
    LOCAL_STORAGE__ANONYMOUS_CART_UUID,
    LOCAL_STORAGE__AUTHENTICATED_CART_UUID,
    SESSION_STORAGE__TOKEN
} from '@/plugins/constants'
import { getCartDropdown } from '@/services/cart'

export async function clearAuthentication() {
    await store.commit('setAuthentication', null)
    await sessionStorage.removeItem(SESSION_STORAGE__TOKEN)
}

export function setAuthentication(token) {
    store.commit('setAuthentication', token)
    sessionStorage.setItem(SESSION_STORAGE__TOKEN, token)
}

export function getCartUUID(isAuthenticated) {
    return localStorage.getItem(isAuthenticated ? LOCAL_STORAGE__AUTHENTICATED_CART_UUID : LOCAL_STORAGE__ANONYMOUS_CART_UUID)
}

export function setCartUUID(cartUUID, isAuthenticated) {
    localStorage.setItem(isAuthenticated ? LOCAL_STORAGE__AUTHENTICATED_CART_UUID : LOCAL_STORAGE__ANONYMOUS_CART_UUID, cartUUID.toString())
}

export async function refreshCartDropdown() {
    store.commit('setLoadingCartDropdown', true)

    const cartDropdown = await getCartDropdown(
        getCartUUID(store.getters.isAuthenticated),
        store.getters.isAuthenticated
    )

    store.commit('setLoadingCartDropdown', false)
    await store.commit('setCartDropdown', cartDropdown)
}

export function scrollToTop() {
    window.scrollTo(0, 0)
}

export function generateRandomString(length = 8) {
    const chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'

    let randomString = ''

    for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * chars.length)
        randomString += chars.charAt(randomIndex)
    }

    return randomString
}