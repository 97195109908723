<template>
  <div class="loader" :class="additionalClass">
    <div class="spinner-border text-dark" role="status" :aria-label="$t('messages.loading')">
      <span class="sr-only"><!--{{ $t('messages.loading') }}--></span>
    </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Loader',
  props: [
      'additionalClass'
  ]
}
</script>