export const TITLE = 'Backfire Design'
export const DEFAULT_PAGINATION_LIMIT = 10
export const MOBILE_MAX_WIDTH_PX = 767

export const HOMEPAGE_GALLERY_1_CODE = 'HOMEPAGE_SECTION_1'
export const HOMEPAGE_GALLERY_2_CODE = 'HOMEPAGE_SECTION_2'
export const HOMEPAGE_GALLERY_3_CODE = 'GALLERY_SITE'


// default routes
export const LOGIN_SUCCESS_REDIRECT_URL = '/'
export const REGISTER_SUCCESS_REDIRECT_URL = '/login'
export const DEFAULT_AUTHENTICATED_REDIRECT_URL = '/'
export const LANGUAGE_CHANGE_REDIRECT_URL = '/'
export const DEFAULT_NOT_AUTHENTICATED_REDIRECT_URL = '/login'
export const LOGOUT_URL = '/logout'


// alerts
export const ALERT_TIMEOUT = 3500
export const ALERT_TYPE_INFO = 'info'
export const ALERT_TYPE_SUCCESS = 'success'
export const ALERT_TYPE_WARNING = 'warning'
export const ALERT_TYPE_DANGER = 'danger'
export const ALERT_TYPES = [
    ALERT_TYPE_INFO,
    ALERT_TYPE_SUCCESS,
    ALERT_TYPE_WARNING,
    ALERT_TYPE_DANGER
]


// storage / cookies
export const SESSION_STORAGE__FOOTER_SECTIONS = '__bfd_footer_sections'
export const SESSION_STORAGE__NAVBAR_SECTIONS = '__bfd_navbar_sections'
export const SESSION_STORAGE__COUNTRIES = '__bfd_countries'
export const SESSION_STORAGE__TRANSLATIONS_PREFIX = '__bfd_translations__'
export const SESSION_STORAGE__TOKEN = '__bfd_token'

export const LOCAL_STORAGE__AUTHENTICATED_CART_UUID = '__bfd_authenticated_cart_uuid'
export const LOCAL_STORAGE__ANONYMOUS_CART_UUID = '__bfd_anonymous_cart_uuid'
export const LOCALE_ISO = 'LOCALE_ISO'  // local storage, window
export const LOCAL_STORAGE__COOKIES_ACCEPTED = '__bfd_cookies_accepted'
export const SESSION_STORAGE__CURRENCY_CODE = '__bfd_currency_code'
export const SESSION_STORAGE__CURRENCY_DATA = '__bfd_currency_data'


// locales / languages
export const DEFAULT_LOCALE_ISO = 'pl'
export const AVAILABLE_LOCALES = [
    'pl',
    'en',
]

// currencies
export const DEFAULT_CURRENCY_CODE = 'pln'
export const AVAILABLE_CURRENCIES = [
    'pln',
    'eur'
]

// forms
export const MAXLENGTH_PHONE_NUMBER = 15
export const MAXLENGTH_EMAIL = 255
export const MAXLENGTH_CONTACT_MESSAGE = 4095
export const MAXLENGTH_NAME = 254
export const MAXLENGTH_POST_CODE = 15
export const MAXLENGTH_CITY = 127
export const MAXLENGTH_STREET = 127
export const MAXLENGTH_TAX_NUMBER = 31
export const FILE_INPUT_ACCEPTED_EXTENSIONS = '.avif,.jpeg,.jpg,.png,.svg,.webp,.bmp,.tiff,.pdf'
export const FILE_MAX_SIZE = 134217728

// product inputs
export const INPUT_CODE_TEXT = 'TEXT'
export const INPUT_CODE_TEXTAREA = 'TEXTAREA'
export const INPUT_CODE_IMAGE = 'IMAGE'
export const INPUT_CODE_IMAGES = 'IMAGES'


// cart addditions
export const CART_ADDITION_DISCOUNT_CODE = 'DISCOUNT_CODE'


// export const SHIPX_URL = 'https://sandbox-api-shipx-pl.easypack24.net'
// export const INPOST_GEOWIDGET_TOKEN = 'eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJzQlpXVzFNZzVlQnpDYU1XU3JvTlBjRWFveFpXcW9Ua2FuZVB3X291LWxvIn0.eyJleHAiOjIwMTQwNTM2MzIsImlhdCI6MTY5ODY5MzYzMiwianRpIjoiYWQ0MGZmYjgtNDkwMy00YjVlLWEwMTEtYjk1MzIxNTIzM2NlIiwiaXNzIjoiaHR0cHM6Ly9sb2dpbi5pbnBvc3QucGwvYXV0aC9yZWFsbXMvZXh0ZXJuYWwiLCJzdWIiOiJmOjEyNDc1MDUxLTFjMDMtNGU1OS1iYTBjLTJiNDU2OTVlZjUzNTpURUlEY1lURTc0SG1Cand1NGVxaGZmNWEwVmdhZkZfUWdYMkFWWVQxSzVBIiwidHlwIjoiQmVhcmVyIiwiYXpwIjoic2hpcHgiLCJzZXNzaW9uX3N0YXRlIjoiMjMwYTQwY2EtNDIwYy00ZTI4LTgxMGEtZjMyNTQwMGIzZDI2Iiwic2NvcGUiOiJvcGVuaWQgYXBpOmFwaXBvaW50cyIsInNpZCI6IjIzMGE0MGNhLTQyMGMtNGUyOC04MTBhLWYzMjU0MDBiM2QyNiIsImFsbG93ZWRfcmVmZXJyZXJzIjoiZGV2LmJhY2tmaXJlZGVzaWduLmxvY2FsIiwidXVpZCI6IjQ0YzVlMjM5LWQ5OTQtNDE2Mi1iM2Q2LTIwMjhlNDc1YjdlNiJ9.ByeXXvbVcytV7EuxSZN80MsjJPhbp3NZWB75TG11SuYDMApXdpMz6bkeEDN8sSGNnp5ae_j8y20j14m3i55kcndZJ7oes-Vu37_VG7l0s_2PYXXn7PxnMXHwrD2DmiFquHZydpesghhp155cYCfX4J6VgX3ixHmmuG5SLomkTbn5I0kZs-lVIyXlweDOqqtfD6SPTSRXWzIMQALXYo3xE2nIYTrC4iQIk1XpoFIyqJDWHmpfpBrERl99oDp3wZa_HzfzDaoQj0U8Cgz_vgKPWp_0Un2S5VZFEY8-VgGWrzUDlmML8Q9u5uy37JhBgqtcyrkif_wgw6QRXBn5rU1C_A'
export const SHIPMENT_TYPE_INPOST_PACKAGE ='inpost_locker_standard'
export const SHIPMENT_TYPE_INPOST_COURIER ='inpost_courier_standard'
export const PAYMENT_STATUS_ACCEPTED = 'ACCEPTED'
export const PAYMENT_TYPE_P24 = 'P24'
