<template>
  <button
      class="btn btn-dark border-0 text-white d-sm-none btn-navbar-toggler"
      aria-label="Toggle navigation"
      @click="toggleMenu"
  >
    <font-awesome-icon icon="bars" size="lg" />
  </button>
</template>

<script>
export default {
  name: 'MainHeaderToggler',
  emits: [
      'toggle-menu'
  ],
  methods: {
    toggleMenu() {
      this.$emit('toggle-menu')
    }
  }
}
</script>