<template>
  <div class="navbar-categories d-none d-sm-flex">
    <div
        v-for="(navbarSection, index) in navbarSections"
        :key="navbarSection.id"
        class="navbar-category-container custom-dropdown"
        :class="{'d-md-none d-xxl-inline-block': index >= 5, 'd-sm-none d-md-inline-block': index >= 3 && index <= 4}"
    >
      <template v-if="isNavbarSectionTogglable(navbarSection)">
        <a
            class="navbar-category nav-link custom-dropdown-button"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            @mouseenter="navbarSection.children && navbarSection.children.length ? showNavbarCategory(navbarSection.id) : hideNavbarCategories()"
            :href="navbarSection.url ? navbarSection.url : (navbarSection.productGroupId ? `/group/${navbarSection.productGroupId}` : '#')"
        >
          {{ navbarSection.name }}
        </a>
        <div
            :ref="getNavbarCategoryDropdownRefName(navbarSection.id)"
            class="navbar-vehicles js-navbar-vehicles"
            :class="{'d-block': isCategoryOpen(navbarSection.id)}"
        >
          <div class="row col-lg-12 justify-content-center mx-1">
            <template
                v-for="navbarCategory2 in navbarSection.children"
                :key="`navbar-category-2-${navbarCategory2.productGroupId}`"
            >
              <div class="col-lg-2 my-3 text-center">
                <a
                    class="mb-3 pb-2 fw-bold fs-4 navbar-vehicle-type fs-5 d-block text-white text-decoration-none"
                    :href="`/group/${navbarCategory2.productGroupId}`"
                >
                  {{ navbarCategory2.name }}
                </a>

                <a
                    v-for="navbarCategory3 in navbarCategory2.children"
                    :key="`navbar-category-3-${navbarCategory3.productGroupId}`"
                    class="fs-6 navbar-vehicle-link"
                    :href="`/group/${navbarCategory3.productGroupId}`"
                >
                  {{ navbarCategory3.name }}
                </a>
              </div>
            </template>
          </div>
        </div>
      </template>
      <template v-else-if="hasElements(navbarSection)">
        <a
            :href="navbarSection.url ?? '#'"
            class="navbar-category nav-link custom-dropdown-button"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            @mouseenter="hideNavbarCategories()"
        >
          {{ navbarSection.name }}
        </a>
        <div class="custom-dropdown-content shadow-lg">
          <a
              v-for="element in navbarSection.elements"
              :key="element.identity"
              :href="element.url"
              class="custom-dropdown-item nav-link"
          >
            {{ element.name }}
          </a>
        </div>
      </template>
      <template v-else>
        <a
            :href="navbarSection.url"
            class="navbar-category nav-link"
            @mouseenter="hideNavbarCategories()"
        >
          {{ navbarSection.name }}
        </a>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MainHeaderCategoriesDesktop',
  props: [
    'navbarSections',
  ],
  data() {
    return {
      visibleCategories: []
    }
  },
  methods: {
    hasElements(navbarSection) {
      return navbarSection.elements && navbarSection.elements.length
    },
    isNavbarSectionTogglable(navbarSection) {
      return navbarSection.children && navbarSection.children.length
    },
    isCategoryOpen(categoryId) {
      return this.visibleCategories && this.visibleCategories.length && this.visibleCategories.includes(categoryId)
    } ,
    getNavbarCategoryDropdownRefName(categoryId) {
      return `navbar-category-dropdown-ref-${categoryId}`
    },
    showNavbarCategory(categoryId) {
      this.visibleCategories = []
      this.visibleCategories.push(categoryId)
    },
    hideNavbarCategories() {
      this.visibleCategories = []
    }
  }
}
</script>

