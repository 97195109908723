<template>
  <div
      class="navbar-icons d-flex"
  >
    <a
        href="/cart"
        class="navbar-cart-dropdown nav-link"
        @mouseover="openDropdownCart"
        aria-label="Cart"
    >
      <font-awesome-icon icon="shopping-cart" size="2x" />
      <span v-if="cartProductsCount" class="badge badge-light cart-products-count-badge">
        {{ cartProductsCount }}
      </span>
    </a>

    <CartDropdown
        v-if="!isMobile && cartOpened"
    />

    <template v-if="isAuthenticated">
      <a href="/account" class="navbar-user nav-link d-none d-sm-block" aria-label="Account">
        <font-awesome-icon icon="user" size="2x" />
      </a>
      <a href="/logout" class="navbar-logout nav-link d-none d-sm-block text-danger" aria-label="Logout">
        <font-awesome-icon icon="sign-out" size="2x" />
      </a>
    </template>
    <a v-else href="/login" class="navbar-user nav-link d-none d-sm-block" aria-label="Login">
      <font-awesome-icon icon="user" size="2x" />
    </a>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

const CartDropdown = defineAsyncComponent(() => import('@/components/CartDropdown'))

export default {
  name: 'MainHeaderIcons',
  emits: [
      'openDropdownCart',
      'closeDropdownCart'
  ],
  props: [
      'cartOpened'
  ],
  components: {
    CartDropdown
  },
  computed: {
    isAuthenticated() {
      return this.$store.getters.isAuthenticated
    },
    cartProductsCount() {
      return this.$store.getters.cartProductsCount
    }
  },
  methods: {
    openDropdownCart() {
      this.$emit('openDropdownCart')
    },
    closeDropdownCart(e) {
      this.$emit('closeDropdownCart', e)
    },
  }
}
</script>